import Head from "next/head";
import Link from "next/link";
import React from "react";
import IntuChargeLogoLight from "../Icons/IntuChargeLogoLight";

const UnderMaintainance: React.FC = (): JSX.Element => (
  <>
    <Head>
      <title>Under Maintainance</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content="404 page" />
    </Head>
    <div className="flex h-[85vh] flex-col bg-transparent">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <Link href="/dashboard">
            <span className="inline-flex">
              <span className="sr-only">
                <span>Intuions</span>
              </span>
              <IntuChargeLogoLight />
            </span>
          </Link>
        </div>
        <div className="py-16">
          <div className="text-center">
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Intuions is currently down for maintenance.
            </h1>
            <p className="mt-2 text-base text-gray-500">
              we expect to be back in a couple hours. thanks for your patience.
            </p>
          </div>
        </div>
      </main>
    </div>
  </>
);

export default UnderMaintainance;
